let gainsEnvironment = 'dev';
// let ENVIRONMENT = 'prod'

const devConfig = {
	apiGateway: {
		URL: 'https://api-dev.gains.earthi.world',
		REGION: 'eu-west-2'
	},
	cognito: {
		REGION: 'eu-west-2',
		USER_POOL_ID: 'eu-west-2_oKbWrU50D',
		APP_CLIENT_ID: '151dhepbp0uu460t0dbd2bivvu',
		IDENTITY_POOL_ID: 'eu-west-2:471e29e3-6266-450c-aef0-13c08bc87e6a'
	},

	idleTimeout: 1000 * 60 * 30 * 3
};

const prodConfig = {
	apiGateway: {
		URL: 'https://api.gains.earthi.world',
		REGION: 'eu-west-2'
	},
	cognito: {
		REGION: 'eu-west-2',
		USER_POOL_ID: 'eu-west-2_plUl26ZwS',
		APP_CLIENT_ID: '5c5i4aom7t7tcrftcr5bt7euvs',
		IDENTITY_POOL_ID: 'eu-west-2:2ec2c7a7-e87e-4e4d-adab-55d302dfa533',
		AuthenticationFlowType: 'USER_PASSWORD_AUTH'
	},
	idleTimeout: 1000 * 60 * 30
};

const devBaseUrl = 'https://h0n2gp2t3k.execute-api.eu-west-2.amazonaws.com/dev';
const prodBaseUrl =
	'https://vukufy6563.execute-api.eu-west-2.amazonaws.com/prod';

let config;
let baseUrl;

switch (gainsEnvironment) {
	case 'prod':
		config = prodConfig;
		baseUrl = prodBaseUrl;
		break;
	case 'dev':
	default:
		config = devConfig;
		baseUrl = devBaseUrl;
		break;
}

const tableNames = {
	stockpiles: 'ScrapSteelStockpilesCaptureProcessingData',
	smelters: 'SteelCaptureProcessingData'
};

const endpoints = {
	// general / stats / dashboard

	// general for diff site types
	captures: `captures`,
	sites: 'sites',
	commodities: 'commodities',
	resetCaps: 'reset_captures',
	captureImageData: 'capture_image',
	qcAvailableCaps: 'available-captures',

	// smelters specific
	hotspotsProfile: 'hotspot_profile',
	barplot: 'barplot',

	// stockpiles specific
	pixelCount: 'pixel_count',

	// upload
	postqc: 'post_qc',
	upload: 'upload'
};

const commodities = [
	{
		commodity: 'Steel',
		id: 'steel',
		index: 3,
		class: 'captures-criteria-comms-steel'
	},
	{
		commodity: 'Copper',
		id: 'copper',
		index: 2,
		class: 'captures-criteria-comms-copper'
	},
	{
		commodity: 'Nickel',
		id: 'nickel',
		index: 1,
		class: 'captures-criteria-comms-nickel'
	},
	{
		commodity: 'Titanium',
		id: 'titanium',
		index: 4,
		class: 'captures-criteria-comms-titanium'
	}
];

const qcStates = [
	{
		stateString: 'active',
		stateInt: 1,
		className: 'text-primary'
	},
	{
		stateString: 'inactive',
		stateInt: 0,
		className: 'text-secondary'
	},
	{
		stateString: 'invalid',
		stateInt: -1,
		className: 'text-warning'
	},
	{
		stateString: '',
		stateInt: null,
		className: ''
	}
];

const qcUsers = [
	{
		name: 'George',
		value: 'George',
		logoColour: 'rgb(176,218,255)',
		logoTextColour: 'white',
		initials: 'GB',
		qcAlias: 'george.begkas',
		email: 'george.begkas@earthi.co.uk'
	},
	{
		name: 'Gordana',
		value: 'Nikolic',
		logoColour: 'rgb(32, 178, 170)',
		logoTextColour: 'white',
		initials: 'GN',
		qcAlias: 'gordana.nikolic',
		email: 'gordana.nikolic@earthi.co.uk'
	},
	{
		name: 'Qurratul',
		value: 'Khan',
		logoColour: 'rgb(72, 61, 139)',
		logoTextColour: 'white',
		initials: 'QK',
		qcAlias: 'qurratul.khan',
		email: 'qurratul.khan@earthi.co.uk'
	},
	{
		name: 'Guy',
		value: 'Guy',
		logoColour: 'rgb(0,135,90)',
		logoTextColour: 'white',
		initials: 'GC',
		qcAlias: 'guy.cook',
		email: 'guy.cook@earthi.co.uk'
	},
	{
		name: 'Madiha',
		value: 'Madiha',
		logoColour: 'rgb(255,153,31)',
		logoTextColour: 'white',
		initials: 'MA',
		qcAlias: 'madiha.abbas',
		email: 'madiha.abbas@earthi.co.uk'
	},
	{
		name: 'Peter',
		value: 'Peter',
		logoColour: 'rgb(23,43,77)',
		logoTextColour: 'white',
		initials: 'PH',
		qcAlias: 'peter.hausknecht',
		email: 'peter.hausknecht@earthi.co.uk'
	},
	{
		name: 'Varsha',
		value: 'Varsha',
		logoColour: 'rgb(0, 128, 128)',
		logoTextColour: 'white',
		initials: 'VP',
		qcAlias: 'varsha.pradhan',
		email: 'chetan.pradhan@earthi.co.uk'
	},
	{
		name: 'Lucy',
		value: 'Lucy',
		logoColour: 'rgb(70, 130, 180)',
		logoTextColour: 'white',
		initials: 'LC',
		qcAlias: 'lucy.colley',
		email: 'lucy.colley@earthi.co.uk'
	},
	{
		name: 'Josh',
		value: 'Josh',
		logoColour: 'rgb(82,62,170)',
		logoTextColour: 'white',
		initials: 'JL',
		qcAlias: 'joshua.large'
	}
];

const qcAdjust = [
	{
		code: 0,
		from: -1, // inactive
		to: -1 // inactive
	},
	{
		code: 0,
		from: 1, // active
		to: 1 // active
	},
	{
		code: 0,
		from: 0, // invalid
		to: 0 // invalid
	},
	{
		code: 1, // 1 Inactive to Active
		from: -1,
		to: 1
	},
	{
		code: 2, // 2 Active to Inactive
		from: 1,
		to: -1
	},
	{
		code: 3, // 3 Active to Invalid
		from: 1,
		to: 0
	},
	{
		code: 4, // 4 Inactive to Invalid
		from: -1,
		to: 0
	},
	{
		code: 7, // 7 Invalid to Active
		from: 0,
		to: 1
	},
	{
		code: 8, // 7 Invalid to Inactive
		from: 0,
		to: -1
	}
];

const production_states = [
	{
		state: -1,
		descript:
			"Not daily qc'ed. Not in staging or production. Not part of trial smelters and doesn't contribute towards global index",
		formText: 'Titanium site'
	},
	{
		state: 0,
		descript:
			"Daily qc'ed. In staging and production. Contributes to global index",
		formText: 'Available in UI'
	},
	{
		state: 1,
		descript:
			"Daily qc'ed. In staging and production. In trial smelters. Does not contribute to global index",
		formText: 'Available in UI but excl from index calcs'
	},
	// {
	// 	state: 2,
	// 	descript: 'Daily qc\'ed. In staging, not production. In trial smelters. Does not contribute to global index'
	// },
	// {
	// 	state: 3,
	// 	descript: 'Not daily qc\'ed. In staging and prodcuction. Contributes to global index'
	// },
	// {
	// 	state: 4,
	// 	descript: 'Not daily qc\'ed. In staging and production. In trial smelters'
	// },
	{
		state: 5,
		descript:
			"Not daily qc'ed. In staging, not production. In trial smelters. Does not contribute to global index",
		formText: 'Part of daily pre_qc but not in UI'
	}
	// {
	// 	state: 6,
	// 	descript: 'Not daily qc\'ed. In staging, not production. Contributes to global index'
	// }
];

const sentinelEval =
	'Ly9WRVJTSU9OPTMKZnVuY3Rpb24gc2V0dXAoKSB7CiAgcmV0dXJuIHsKICAgIGlucHV0OiBbIkIxMiIsIkIxMSIsIkI4QSIsICJkYXRhTWFzayJdLAogICAgb3V0cHV0OiB7IGJhbmRzOiA0IH0KICB9Owp9CgpmdW5jdGlvbiBldmFsdWF0ZVBpeGVsKHNhbXBsZSkgewogIAogIHJldHVybiBbMi41ICogc2FtcGxlLkIxMiwgMi41ICogc2FtcGxlLkIxMSwgMi41ICogc2FtcGxlLkI4QSwgc2FtcGxlLmRhdGFNYXNrXTsKfQ%3D%3D';
const landsatEval =
	'Ly9WRVJTSU9OPTMKZnVuY3Rpb24gc2V0dXAoKSB7CiAgcmV0dXJuIHsKICAgIGlucHV0OiBbIkIwNyIsIkIwNiIsIkIwNSIsICJkYXRhTWFzayJdLAogICAgb3V0cHV0OiB7IGJhbmRzOiA0IH0KICB9Owp9CgpmdW5jdGlvbiBldmFsdWF0ZVBpeGVsKHNhbXBsZSkgewogIAogIHJldHVybiBbMi41ICogc2FtcGxlLkIwNywgMi41ICogc2FtcGxlLkIwNiwgMi41ICogc2FtcGxlLkIwNSwgc2FtcGxlLmRhdGFNYXNrXTsKfQ%3D%3D';

export default {
	...config,
	baseUrl,
	commodities,
	production_states,
	qcStates,
	qcUsers,
	qcAdjust,
	sentinelEval,
	landsatEval,
	endpoints,
	tableNames
};
